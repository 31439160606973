* {
	-ms-overflow-style: none;
}
::-webkit-scrollbar {
	display: none;
}

@keyframes colorChange {
	from {
		filter: hue-rotate(0deg);
	}
	to {
		filter: hue-rotate(360deg);
	}
}
